export const baseMarginPage = 20

export const CSS_UNITS = 96.0 / 72.0

export const shapeClassname = {
  MOUSE: 'Mouse',
  CIRCLE: 'Circle',
  ARROW: 'Arrow',
  RECT: 'Rect',
  LINE: 'Line',
}

export const toolboxActionHandler = {
  MOUSE_MOVE: 'MOUSE_MOVE',
  MOUSE_CLICK: 'MOUSE_CLICK',
}

export const toolboxColorPalette = {
  BLUE: '#00adee',
  RED: '#E62154',
  GREEN: '#8dc63f',
  YELLOW: '#f7941d',
  PINK: '#f06eaa',
  PURPLE: '#417B92',
}

export const attrsShapes = {
  strokeWidth: 5,
  pointerLength: 10,
  pointerWidth: 10,
  radius: 10,
  shadowBlur: 25,
  shadowOpacity: 1,
  shapeFill: 'rgba(255, 255, 255, 0)',
}

export const positionCommentPlace = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
}

export const typeDisplayAnnotation = {
  PDF: 'pdf',
  IMAGE: 'img',
  UNSUPPORT: 'unsupport',
}

export const reviewerAbilityStruct = {
  MAKE_CHANGES: 'MAKE_CHANGES',
  STAY_IN_REVIEW: 'STAY_IN_REVIEW',
  PUT_ON_HOLD: 'PUT_ON_HOLD',
  ORDER_APPROVED: 'ORDER_APPROVED',
}

export const attachmentTypeStartFromStruct = {
  INTERNAL_REVIEW: 'internal',
  CUSTOMER_REVIEW: 'customer',
  SUPPLIED_FILE: 'supplied',
  GUEST_REVIEW: 'guest',
}

export const orientationStruct = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  SQUARE: 'square',
}

export const pdfAnnotationModules = {
  pdfjs: 'pdfjs',
  pdfTools: 'pdf-tools',
}
