import * as actionTypes from './actionTypes'
import { AuthAPI } from '../../api/User/Auth'
import toastCenter from '../../shared/toastCenter'
import clientLogin from '../../shared/clientLogin'
import { loadUserDetail, failedLoaded as emptyLoaded } from './userDetail'
import { getParamLoginEmit, socketEmit } from '../../socket/socket'
import socketEvent from '../../socket/socketEvent'
import { resetKanban } from '../../shared/utility'
import { LogoutAPI } from '../../api/User/Logout'
import { flashHide } from './flashNotification'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const authStop = () => {
  return {
    type: actionTypes.AUTH_STOP,
  }
}

export const authSuccess = (token, userId, role, clientId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    role: role,
    clientId: clientId,
  }
}

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  }
}

export const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  }
}

export const userDetailSuccess = (user) => {
  return {
    type: actionTypes.USER_LOADED_SUCCESS,
    user,
  }
}

export const logout = () => (dispatch) => {
  const userType = localStorage.getItem('clientId')

  if (userType === clientLogin.ADMIN) {
    const logoutApi = new LogoutAPI()
    const onNext = () => {}
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
    }

    logoutApi.subscribe(onNext, onComplete, onError)
  }

  resetKanban()
  dispatch(flashHide())
  dispatch(emptyLoaded())
  dispatch(authLogout())
}

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime)
  }
}

export const auth = (
  username,
  password,
  isAdmin = false,
  callbackInvalidLogin = () => {}
) => {
  return (dispatch) => {
    dispatch(authStart())

    const authApi = new AuthAPI(username, password)
    const onNext = (response) => {
      if (response.statusCode !== undefined && response.statusCode === '400') {
        dispatch(authFail(response))
        toastCenter.messageServerError()
      } else {
        const { accessToken, accessTokenExpiresAt, user, client } = response
        const expiresIn =
          new Date(accessTokenExpiresAt).getTime() - new Date().getTime()
        localStorage.setItem('clientId', client.id)
        localStorage.setItem('token', accessToken)
        localStorage.setItem('expirationDate', accessTokenExpiresAt)
        localStorage.setItem('userId', user.id)
        // load user detail
        dispatch(loadUserDetail())
        // dispatch(authSuccess(accessToken, user.id, user.role, client.id));
        dispatch(checkAuthTimeout(expiresIn))
        socketEmit(socketEvent.LOGIN, getParamLoginEmit(client.id, accessToken))
      }
    }
    const onError = (err) => {
      const response =
        err && err.response && err.response.data ? err.response.data : undefined
      if (response && response.name && response.name === 'invalid_grant') {
        const errorMessage = {
          message: 'Incorrect username or password.',
          messages: [
            'The email and password you entered did not match our records. Please double-check and try again.',
          ],
        }
        toastCenter.messageServerErrorCustom(errorMessage)

        if (typeof callbackInvalidLogin === 'function') {
          callbackInvalidLogin()
        }
      } else {
        toastCenter.messageServerError()
      }

      dispatch(authFail({ message: 'Error encountered, please contact admin' }))
    }
    const onComplete = () => {}

    if (isAdmin) {
      authApi.subscribeToDesigndesk(onNext, onComplete, onError)
    } else {
      authApi.subscribeToCustomer(onNext, onComplete, onError)
    }
  }
}

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  }
}

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token')
    const clientId = localStorage.getItem('clientId')
    const expirationDate = localStorage.getItem('expirationDate')
    const userId = localStorage.getItem('userId')
    dispatch(authStart())

    if (!token || !userId || !expirationDate || !clientId) {
      dispatch(authStop())
      dispatch(logout())
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'))
      if (expirationDate <= new Date()) {
        dispatch(authStop())
        dispatch(logout())
      } else {
        const expirationDate = new Date(localStorage.getItem('expirationDate'))
        if (expirationDate <= new Date()) {
          dispatch(authStop())
          dispatch(logout())
        } else {
          if (clientId === clientLogin.CUSTOMER) {
            // load user detail
            dispatch(loadUserDetail())
          } else {
            dispatch(loadUserDetail())
          }
          socketEmit(socketEvent.LOGIN, getParamLoginEmit(clientId, token))
          dispatch(
            checkAuthTimeout(expirationDate.getTime() - new Date().getTime())
          )
        }
      }
    }
  }
}
