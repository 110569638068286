import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// import Logo from '../Logo/Logo'
import Modal from '../Modal/Modal'
import * as Router from '../../shared/router'
import { handleLogo } from '../../shared/utility'

const UserWelcome = ({ show, onHide, user }) => {
  return (
    <Modal
      show={show}
      onHideModal={onHide}
      isForceShow={true}
      modalSize="modal-md"
      useClose={false}
    >
      <div className="d-flex flex-column">
        <div className="mb-3">{handleLogo(user)}</div>
        <div className="mb-3">
          <span className="text-16 font-weight-bold">
            Welcome to Dashboard!
          </span>
        </div>
        <div className="">
          <p>
            You must complete your profile information before you can use your
            account
          </p>
        </div>
        <div>
          <NavLink to={Router.manageProfile} className="btn btn-darkprimary">
            <span>Go to manage profile</span>
          </NavLink>
        </div>
      </div>
    </Modal>
  )
}

UserWelcome.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default UserWelcome
