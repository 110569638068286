import userRole from './userRole'
import orderProgress from './orderProgress'
import branding from './branding'

export const getRoleTitle = (role) => {
  switch (role) {
    case userRole.MANAGER:
      return `${branding.NAME} Manager`
    case userRole.OPERATOR:
      return `${branding.NAME} Operator`
    case userRole.STAFF:
      return 'Team Member'
    case userRole.CREATIVE_MANAGER:
      return 'Creative Manager'
    case userRole.PRODUCTION_MANAGER:
      return 'Production Manager'
    default:
      return 'Account Owner'
  }
}

export const checkOrderCreator = (creatorId) => {
  const userId = localStorage.getItem('userId')

  return userId === creatorId
}

export const checkOrderCollaborator = (collaborator = []) => {
  const userId = localStorage.getItem('userId')

  const index = collaborator.findIndex((c) => c.id === userId)

  return index > -1
}

export const checkAbilityOrder = (orderDetail, isAbleToUpdate) => {
  if (
    (orderDetail && checkOrderCreator(orderDetail.creator.id)) ||
    isAbleToUpdate
  ) {
    return true
  }

  return false
}

export const checkAbilityOrderCollaborator = (orderDetail, isAbleToUpdate) => {
  if (
    (orderDetail && checkOrderCreator(orderDetail.creator.id)) ||
    (orderDetail && checkOrderCollaborator(orderDetail.collaborators)) ||
    isAbleToUpdate
  ) {
    return true
  }

  return false
}

export const checkAbilityDeleteOrderAttachment = (
  user,
  uploadedBy,
  orderDetail,
  isAbleToUpdate
) => {
  if (
    (orderDetail && checkOrderCreator(orderDetail.creator.id)) ||
    isAttachmentUploadedByUser(user, uploadedBy) ||
    isAbleToUpdate
  ) {
    return true
  }

  return false
}

export const isAttachmentUploadedByUser = (user, uploadedBy) => {
  if (`${user.firstname} ${user.lastname}` === uploadedBy) {
    return true
  }

  return false
}

export const isAttachmentUploadedByTypeUserCustomer = (uploadedByType) => {
  if (uploadedByType === 'customer') {
    return true
  }

  return false
}

export const checUserTypeOrder = (orderDetail) => {
  if (checkOrderCreator(orderDetail.creator.id)) {
    return 'creator'
  } else if (checkOrderCollaborator(orderDetail.collaborators)) {
    return 'collaborator'
  }

  return 'guest'
}

export const checkAbilityDownloadOrderAttachment = (
  user,
  file,
  orderDetailProgress
) => {
  if (
    user.role === userRole.MANAGER ||
    user.role === userRole.OPERATOR ||
    user.role === userRole.CREATIVE_MANAGER ||
    user.role === userRole.PRODUCTION_MANAGER
  ) {
    return true
  } else {
    if (isAttachmentUploadedByTypeUserCustomer(file.uploadedByType)) {
      return true
    } else {
      if (
        orderDetailProgress === orderProgress.APPROVED ||
        orderDetailProgress === orderProgress.COMPLETED
      ) {
        return true
      }
    }
  }

  return false
}

// https://stackoverflow.com/questions/14850553/javascript-regex-for-password-containing-at-least-8-characters-1-number-1-uppe
export const isPasswordValid = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/
  return re.test(password)
}

export const getInitialAvatar = (firstname = '', lastname = '') => {
  if (lastname === '') {
    if (firstname.split(' ').length > 1) {
      const arrFullname = firstname.split(' ')
      return (
        arrFullname[0].charAt(0).toUpperCase() +
        arrFullname[1].charAt(0).toUpperCase()
      )
    }

    return (
      firstname.charAt(0).toUpperCase() +
      firstname.charAt(firstname.length - 1).toUpperCase()
    )
  }

  if (firstname === '') {
    return (
      lastname.charAt(0).toUpperCase() +
      lastname.charAt(lastname.length - 1).toUpperCase()
    )
  }

  return firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase()
}

export const isFullStorage = (user) => {
  if (user === null || user === undefined) return false

  return user.storages
    ? user.storages.used + user.retail.additionalStorageUsed >=
        user.storages.quota + user.retail.additionalStorageQuota
    : false
}
