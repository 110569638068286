import React from 'react'
import LogoImage from './Logo.png'

function Logo({ width }) {
  const clientId = localStorage.getItem('clientId')
  // TODO: change staging to production
  return clientId === 'user' ? (
    <img src={LogoImage} height={40} alt="Print Ready logo" />
  ) : (
    <img src={LogoImage} height={40} alt="Print Ready logo" />
  )
}

export default Logo
