import React from 'react'
import PropTypes from 'prop-types'

import './AppApiLoader.scss'
// import SvgAppArt from './SvgAppArt'
import Spinner from '../Spinner/Spinner'

const AppApiLoader = ({ isLoading = false, className = '' }) => {
  return (
    <div
      className={`api-loader-logo-wrapp ${className} ${
        isLoading ? 'show' : 'd-none'
      }`}
    >
      <div className="d-flex flex-column position-relative api-loader-shadow">
        <Spinner />
      </div>
    </div>
  )
}

AppApiLoader.propType = {
  isLoading: PropTypes.bool.isRequired,
}

export default AppApiLoader
