import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import '../../../Modal/Modal.css'
import './AdBlockWarning.scss'
import Logo from '../../../Logo/Logo'

const AdBlockWarning = ({ onHide }) => {
  const [cssShow, setCssShow] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setCssShow('show')
    }, 500)
  }, [])

  const handleHideMessage = () => {
    setTimeout(() => {
      onHide()
    }, 100)

    setCssShow('')
  }

  return (
    <div className="guest-message-welcome-wrapper">
      <div className={`modal fade ${cssShow}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mb-3">
                <Logo width={120} />
              </div>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                  <h2>AdBlock Detected</h2>
                  <div className="d-flex flex-column">
                    <p>
                      You must turn off your ad blocking software to view this
                      annotation
                    </p>
                  </div>
                  <div className="d-flex flex-row mt-3">
                    <button
                      type="button"
                      className="btn btn-darkprimary"
                      onClick={handleHideMessage}
                    >
                      Got it!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AdBlockWarning.propTypes = {
  onHide: PropTypes.func,
}

export default AdBlockWarning
