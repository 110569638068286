export default {
  ORANGE: '#f68e56',
  RED: '#E62154',
  DARKBLUE: '#417B92',
  BLUE: '#417B92',
  BLACK: '#417B92',
  DARK: '#4d5256',
  PRIMARY: '#417B92',
  WARNING: '#f68a53',
  ERROR: '#db5656',
}
